import * as React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { RiTwitterLine, RiGithubLine } from "react-icons/ri/"

const About = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const social = data.site.siteMetadata?.social
  const author = data.site.siteMetadata?.author

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="サイト" />
      <article class="blog-post" itemscope="" itemtype="http://schema.org/Article">
        <div className="about">
        <h2>書いてる人</h2>
        <div className="about-detail">
          <h5>{author.name}</h5>
          {/* <p>
          紙媒体やWEBのデザイナー、ソフトウェアエンジニアを経験してきました。
          <br />
          今はプロジェクトマネージャという、会議ばかりする仕事が本職です。
          <br />
          自分でサービス作ってリリースしたいという初心を思い出して、
          <br />
          勉強しながらアウトプットしていきます。
          <br />
          <br />
          問い合わせ先は　contact[at]satokes.uk　まで
          <br />
          TwitterのDMでも可
          <br />
          <Link to={`https://twitter.com/${social?.twitter || ``}`}>
            <RiTwitterLine size={25} />
          </Link>
          <Link to={`https://github.com/${social?.github || ``}`}>
            <RiGithubLine size={25} />
          </Link>
          <br />
          </p> */}
          {/* <h5>作ったもの</h5>
          <table>
            <tbody>
            <tr>
            <th>名前</th>
            <th>場所</th>
            </tr>
            <tr>
            <td>フリーペーパー「ナイスガイ」</td>
            <td>どこかで入手してね</td>
            </tr>
            <tr>
            <td>フリーペーパー「ナイスガイ」のWEBサイト</td>
            <td>
              <Link to={`https://nice-guy.jp`}>nice-guy.jp</Link>
            </td>
            </tr>
            <tr>
            <td>レンラクノート</td>
            <td>
              <Link to={`https://renrakunote.page`}>renrakunote.page</Link>
            </td>
            </tr>
            <tr>
            <td>300万円年収アップした転職正攻法</td>
            <td>
              <Link to={`https://t.co/fJ48DvzAmo?amp=1`}>電子書籍ページへ</Link>
            </td>
            </tr>
            </tbody>
          </table> */}
        </div>
        <div className="policy">
          <h2>プライバシーポリシー</h2>
          <h5>当サイトに掲載されている広告について</h5>
          <p>当サイトでは、第三者配信の広告サービス（Googleアドセンス、Amazonアソシエイト）を利用しています。 このような広告配信事業者は、ユーザーの興味に応じた商品やサービスの広告を表示するため、当サイトや他サイトへのアクセスに関する情報 『Cookie』(氏名、住所、メール アドレス、電話番号は含まれません) を使用することがあります。 またGoogleアドセンスに関して、このプロセスの詳細やこのような情報が広告配信事業者に使用されないようにする方法については、こちらをクリックしてください。
            当サイトは、Amazon.co.jpを宣伝しリンクすることによってサイトが紹介料を獲得できる手段を提供することを目的に設定されたアフィリエイトプログラムである、Amazonアソシエイト・プログラムの参加者です。</p>
          <h5>当サイトが使用しているアクセス解析ツールについて</h5>
          <p>当サイトでは、Googleによるアクセス解析ツール「Googleアナリティクス」を利用しています。 このGoogleアナリティクスはトラフィックデータの収集のためにCookieを使用しています。 このトラフィックデータは匿名で収集されており、個人を特定するものではありません。 この機能はCookieを無効にすることで収集を拒否することが出来ますので、お使いのブラウザの設定をご確認ください。 この規約に関して、詳しくはこちらをクリックしてください。</p>
        </div>
        </div>
      </article>
    </Layout>
  )
}

export default About

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        social {
          twitter
        }
        author {
          name
          summary
        }
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          tags
          cover {
            childImageSharp {
              gatsbyImageData(
                width: 800
                height: 360
                quality: 90
                placeholder: BLURRED
                transformOptions: {cropFocus: CENTER}
                layout: CONSTRAINED
              )
            }
          }
        }
      }
    }
  }
`
